@import '@/app/variables';

.wrapper {
  overflow: hidden;
  display: flex;
  font-family: 'Gilroy', sans-serif;
  margin-bottom: 200px;
  @media(max-width: $bp-m) {
    display: block;
    margin-bottom: 45px;
  }
}

.images {
  width: 50%;
  position: relative;
  @media(max-width: $bp-m) {
    width: 100%;
    height: 400px;
    margin-bottom: 24px;
  }
}

.image {
  position: absolute;
  border-radius: 20px;
  overflow: hidden;
  @media(max-width: $bp-l) {
    transform: scale(0.8);
  }
  @media(max-width: $bp-m) {
    transform: scale(0.55);
  }
  @media(max-width: $bp-xs) {
    transform: scale(0.5);
  }
}

.image.left {
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: 0 0;
}

.image.right {
  bottom: 0;
  right: 0;
  z-index: 2;
  transform-origin: 100% 100%;

}

.content {
  width: 50%;
  padding-left: 60px;
  font-size: var(--fz-20);
  line-height: 34px;
  font-weight: 500;
  @media(max-width: $bp-m) {
    width: 100%;
    padding-left: 0;
  }
}

.title {
  margin-bottom: 30px;
  @media(max-width: $bp-m) {
    margin-bottom: 24px;
  }
}

.description {
  margin-bottom: 30px;
  @media(max-width: $bp-m) {
    margin-bottom: 17px;
  }

}

.list {
  margin-bottom: 40px;
  list-style-type: none;
  max-width: 490px;
}

.list-item {
  margin-bottom: 20px;
  padding-left: 25px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }
}

.list-item.customer {
  &:before {
    background-color: var(--primary-customer-color);
  }
}

.list-item.executor::before {
  background-color: var(--primary-executor-color);
}

.link {
  display: block;
  margin-bottom: 35px;
  border-radius: 7px;
  width: 360px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  color: var(--text-color-inverted);
  font-size: var(--fz-18);
  @media(max-width: $bp-m) {
    width: 100%;
  }
}